import * as React from "react"
import Layout from '../components/layout'

// markup
const IndexPage = () => {
  return (
    <Layout>
      <main>
        <title>Welcome</title>

          <div className="section-6 wf-section">
              <div data-w-id="fee6c11f-454d-a31b-c936-e96f978ddd39" className="v3-1-hero w-container">
                  <div className="home-announcement-container">
                      <div className="div-block-101">
                          <div className="text-block-73">NEW</div>
                          <img src="/images/6243040d301c232fbcd220f6_Group 2121.svg" loading="lazy" alt="" className="image-95"/>
                      </div>
                      <a data-event-category="Home Announcement Link" data-event-action="Click" data-event-label="Roast My Finance Stack"
                         href="https://www.rebanknow.com/roast-my-finance-stack" className="v3-banner-link w-inline-block">
                          <div className="v3-link-text-small">Roast My Finance Stack</div>
                          <img src="/images/6203f94ff8a2e8101731d4c0_arrow_forward.svg" loading="lazy" alt="Arrow icon"
                               className="v3-benefit-link-arrow"/>
                      </a>
                  </div>
                  <h1 id="v3-hero-heading-original" className="v3-1-hero-heading">Put parking on autopilot<br/></h1>
                  <div className="v3-1-hero-text">Track money across all your banks with one login. <br/>Pay and get paid in your customers' currencies — zero
                      delays, no FX
                      markup. <br/>For founders and finance teams of all sizes.
                  </div>
                  <div className="v3-1-get-started-group">
                      <div className="div-block-80">
                          <a data-event-action="Click" data-event-category="Sign Up" data-event-label="Home Hero Module"
                             href="https://app.rebanknow.com/signup" className="v3-1-btn-get-started v3-1-btn-get-started-large w-button"><strong
                              className="bold-text-21">Create your account</strong></a>
                          <a data-event-category="Demo booking" data-event-action="Click"
                             data-event-label="Hero - Book a demo" href="https://calendly.com/leona-rebank/30min"
                             target="_blank" className="v3-btn-book-demo w-inline-block">
                              <div className="text-block-66">Or speak to an expert</div>
                              <img src="/images/6203f94ff8a2e8101731d4c0_arrow_forward.svg" loading="lazy" alt="Arrow icon" className="image-67"/>
                          </a>
                      </div>
                      <div className="div-block-85">
                          <div className="div-block-84">
                              <img src="/images/pink-tick.svg" loading="lazy" alt="" className="image-74"/>
                                  <div className="text-block-68">Try FREE for 14 days</div>
                          </div>
                          <div className="div-block-84">
                              <img src="/images/pink-tick.svg" loading="lazy" alt="" className="image-74"/>
                                  <div className="text-block-68">No card required</div>
                          </div>
                          <div className="div-block-84">
                              <img src="/images/pink-tick.svg" loading="lazy" alt="" className="image-74"/>
                                  <div className="text-block-68">No switching banks</div>
                          </div>
                      </div>
                  </div>
                  <img src="/images/6228da468c3512cfc626c2b9_left-hero-img.svg" loading="lazy" alt="" className="image-75"/>
                  <img src="/images/6228d900cf0cdd5bfd7b31c8_right-hero-img.svg" loading="lazy" alt="" className="image-76"/>
                      <div className="div-block-87">
                          <div className="div-block-86">
                              <img src="/images/6228d8ffcf0cddaf477b31af_padlock.svg" loading="lazy" alt="" className="image-77"/>
                                  <div className="text-block-69">FCA Regulated</div>
                          </div>
                          <div className="div-block-86">
                              <div className="text-block-69">Backed by</div>
                              <img src="/images/622f1bfb3b296239804e603a_yc-logo.svg" loading="lazy" alt="" className="image-78"/>
                          </div>
                      </div>
              </div>
          </div>
          <div className="section-12 wf-section">
              <div className="v3-main w-container">
                  <div className="v3-logos-section v3-logos-companies">
                      <div className="v3-logos">
                          <img src="/images/620a960e516275598ae606c9_sixfold.png" loading="lazy" alt="" className="v3-company-logo v3-logo-sixfold"/>
                          <img src="/images/620a961edff434be76f61008_humanloop.png" loading="lazy" alt="" className="v3-company-logo v3-logo-humaloop"/>
                          <img src="/images/619e60ceb3de7b70790fc114_client-logo-okteto.png" loading="lazy" alt="" className="v3-company-logo v3-logo-okteto"/>
                          <img src="/images/6228d900367147238a5af3f0_yummy.png" loading="lazy" alt="" className="v3-company-logo v3-logo-yummy"/>
                          <img src="/images/620f926aa098d21bc7cf89d7_feastit.svg" loading="lazy" alt="Feast it logo" className="v3-company-logo v3-logo-feastit"/>
                      </div>
                      <div className="v3-logos-separator v3-logos-separator-companies"></div>
                      <a data-event-action="Click" data-event-label="Home - Case studies" data-event-category="Case studies"
                         href="https://www.rebanknow.com/case-studies/feast-it" className="v3-logos-link v3-logos-link-case-studies w-inline-block">
                          <div className="v3-link-logos-text">How <strong>Feast It</strong> streamlines payroll</div>
                          <img src="/images/6203f94ff8a2e8101731d4c0_arrow_forward.svg" loading="lazy" alt="Arrow icon"
                               className="v3-benefit-link-arrow"/>
                      </a>
                  </div>
                  <div className="v3-benefit-row">
                      <div className="div-block-67">
                          <h2 className="v3-benefit-heading">Instantly become the CFO you’ll spend months trying to hire</h2>
                          <a
                              data-event-category="Home Benefits" data-event-action="Click" data-event-label="Master your finances"
                              href="https://www.rebanknow.com/features#metrics" className="v3-link w-inline-block">
                              <div className="v3-link-text">Master your finances without finance expertise</div>
                              <img src="/images/6203f94ff8a2e8101731d4c0_arrow_forward.svg" loading="lazy" alt="Arrow icon"
                                   className="v3-benefit-link-arrow"/>
                          </a>
                      </div>
                      <div className="div-block-90">
                          <img src="/images/6229dde93391b5ea0ae9de8d_hats.svg" loading="lazy" data-w-id="71287bbe-10c2-ccff-15dd-a466ad9a6161" alt="" className="v3-1-benefit-img-1"/>
                          <img src="/images/6214f37bbec7b23bce0ad03b_opportunities-back.svg" loading="lazy" alt="Opportunities backdrop" className="image-61"/>
                      </div>
                  </div>
                  <div className="v3-benefit-row">
                      <div className="div-block-90">
                          <img src="/images/6214d762760c3dfcc47e3c0e_drone.svg" loading="lazy" data-w-id="b1ee4da0-6177-00ac-9762-5de9c7a6f914" alt="Drone carrying cash across borders" className="v3-benefit-img-2"/>
                          <img src="/images/6214d761fa850257971b09a4_drone-clouds.svg" loading="lazy" alt="Clouds" className="image-62"/>
                      </div>
                      <div className="div-block-67">
                          <h2 className="v3-benefit-heading">Reinvest the money you’ve saved from costly foreign payments</h2>
                          <a
                              data-event-action="Click" data-event-category="Home Benefits" data-event-label="Save on transfer fees"
                              href="https://www.rebanknow.com/features#payments" className="v3-link w-inline-block">
                              <div className="v3-link-text">Save 90% on transfer fees</div>
                              <img src="/images/6203f94ff8a2e8101731d4c0_arrow_forward.svg" loading="lazy" alt="Arrow icon"
                                   className="v3-benefit-link-arrow"/>
                          </a>
                      </div>
                  </div>
                  <div className="v3-benefit-row">
                      <div className="div-block-67">
                          <h2 className="v3-benefit-heading">Get legally compliant docs when moving investment overseas</h2>
                          <a data-event-action="Click"
                             data-event-category="Home Benefits"
                             data-event-label="Become tax compliant"
                             href="https://www.rebanknow.com/features#intercompany"
                             className="v3-link w-inline-block">
                              <div className="v3-link-text">Become tax compliant</div>
                              <img src="/images/6203f94ff8a2e8101731d4c0_arrow_forward.svg" loading="lazy" alt="Arrow icon"
                                   className="v3-benefit-link-arrow"/>
                          </a>
                      </div>
                      <div className="div-block-90">
                          <img src="/images/6214d761a48262184317987a_stamp-img.svg" loading="lazy"
                               data-w-id="5ebf6f6f-2b84-d4ab-7140-fc28132b6bf7" alt="Stamp of approval when moving money abroad"
                               className="v3-benefit-img-3"/>
                          <img src="/images/6214f37cc636f5a715f927cf_transfers-back.svg" loading="lazy" alt="Transfers backdrop" className="image-63"/>
                      </div>
                  </div>
                  <div className="v3-benefit-row">
                      <div className="div-block-90">
                          <img src="/images/6214d7619b584e4103ef9ac6_Sit_Back.svg" loading="lazy" data-w-id="c08c0d25-b695-8f01-13e8-6d265f067526"
                               alt="Person sitting with their feet up watching automated finance" className="v3-benefit-img-4"/>
                          <img src="/images/6214d761fd6cef32e4e6bd18_sit-back-back.svg" loading="lazy" alt="Users backdrop" className="image-64"/>
                      </div>
                      <div className="div-block-67">
                          <h2 className="v3-benefit-heading">Invite investors and accountants and share insights, not bank logins</h2>
                          <a
                              data-event-action="Click" data-event-category="Home Benefits" data-event-label="Stop sending reports"
                              href="https://www.rebanknow.com/features#users" className="v3-link w-inline-block">
                              <div className="v3-link-text">Stop sending bank statements and reports</div>
                              <img src="/images/6203f94ff8a2e8101731d4c0_arrow_forward.svg" loading="lazy" alt="Arrow icon"
                                   className="v3-benefit-link-arrow"/>
                          </a>
                      </div>
                  </div>
                  <div className="v3-logos-section">
                      <div className="v3-logos-integrations">
                          <div className="text-block-35">We support your banks and so many more</div>
                          <div className="div-block-42">
                              <div className="v3-logos">
                                  <img src="/images/620b7346f41e1f11cd8c3f91_barclays.png" loading="lazy" alt="" className="v3-bank-logo v3-logo-barclays"/>
                                  <img src="/images/620b73468e74ca674d8f679d_svb.png" loading="lazy" alt="" className="v3-bank-logo v3-logo-svb"/>
                                  <img src="/images/620b7346f41e1f99008c3f92_chase.png" loading="lazy" alt="" className="v3-bank-logo v3-logo-chase"/>
                                  <img src="/images/620b73468dc509db33f5fae9_revolut.png" loading="lazy" alt="" className="v3-bank-logo v3-logo-revolut"/>
                                  <img src="/images/620b73466c266c8606d9eda2_wise.png" loading="lazy" alt="" className="v3-bank-logo v3-logo-wise"/>
                                  <img src="/images/620b7346c292cf41f79f791f_brex.png" loading="lazy" alt="" className="v3-bank-logo v3-logo-brex"/>
                                  <img src="/images/620b742b86b5542b0284a2aa_paypal.png" loading="lazy" alt="" className="v3-bank-logo v3-logo-paypal"/>
                              </div>
                              <div className="v3-logos-separator"></div>
                              <a data-event-label="Integrations" data-event-category="Home Logos Strip" data-event-action="Click"
                                 href="https://www.rebanknow.com/integrations" className="v3-logos-link v3-logos-link-integrations w-inline-block">
                                  <div className="v3-link-logos-text">Integrations</div>
                                  <img src="/images/6203f94ff8a2e8101731d4c0_arrow_forward.svg" loading="lazy" alt="Arrow icon" className="v3-benefit-link-arrow"/>
                              </a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="section-15 wf-section">
              <div className="v3-how-it-works-container w-container">
                  <div className="v3-resources-title">How it works</div>
                  <div className="div-block-89">
                      <div className="div-block-88">
                          <img src="/images/6228d8ff974ef576171b3c45_bank-card.svg" loading="lazy" alt=""/>
                          <div className="text-block-70">Connect all your banks</div>
                          <div className="text-block-71">Ditch the dongles and get one login to 10,000+ supported institutions</div>
                      </div>
                      <div className="div-block-88">
                          <img src="/images/6228d8ffc46c4d1fd7436bc5_wallet.svg" loading="lazy" alt=""/>
                          <div className="text-block-70">Hold multiple currencies</div>
                          <div className="text-block-71">Access new markets with 36+ foreign currencies and local account details</div>
                      </div>
                      <div className="div-block-88">
                          <img src="/images/6228d8ff1512d71b6fb611a2_globe.svg" loading="lazy" alt=""/>
                          <div className="text-block-70">Send global payments</div>
                          <div className="text-block-71">Pay remote teams in 180+ countries on time with scheduled payroll</div>
                      </div>
                  </div>
                  <img src="/images/6229fa5ea216b4250b45c6da_product-ui.png" loading="lazy"
                       sizes="(max-width: 767px) 100vw, (max-width: 1439px) 92vw, 1000px"
                       srcSet="https://assets.website-files.com/600ea824f296c05edccbc9a8/6229fa5ea216b4250b45c6da_product-ui-p-500.png 500w, https://assets.website-files.com/600ea824f296c05edccbc9a8/6229fa5ea216b4250b45c6da_product-ui-p-800.png 800w, https://assets.website-files.com/600ea824f296c05edccbc9a8/6229fa5ea216b4250b45c6da_product-ui-p-1080.png 1080w, https://assets.website-files.com/600ea824f296c05edccbc9a8/6229fa5ea216b4250b45c6da_product-ui-p-1600.png 1600w, https://assets.website-files.com/600ea824f296c05edccbc9a8/6229fa5ea216b4250b45c6da_product-ui.png 1978w"
                       alt="" className="image-79"/>
                      <img src="/images/6229fa5eeb22a987e1eccc2b_product-ui-mobile.png" loading="lazy"
                                                        sizes="(max-width: 479px) 87vw, (max-width: 767px) 90vw, 100vw"
                                                        srcSet="https://assets.website-files.com/600ea824f296c05edccbc9a8/6229fa5eeb22a987e1eccc2b_product-ui-mobile-p-500.png 500w, https://assets.website-files.com/600ea824f296c05edccbc9a8/6229fa5eeb22a987e1eccc2b_product-ui-mobile-p-800.png 800w, https://assets.website-files.com/600ea824f296c05edccbc9a8/6229fa5eeb22a987e1eccc2b_product-ui-mobile-p-1080.png 1080w, https://assets.website-files.com/600ea824f296c05edccbc9a8/6229fa5eeb22a987e1eccc2b_product-ui-mobile.png 1092w"
                                                        alt="" className="image-80"/>
                      <div className="v3-1-product-cta">
                          <div className="text-block-110">Reduce spend, extend your runway</div>
                          <div className="div-block-80">
                              <a data-event-action="Click" data-event-category="Sign Up" data-event-label="Product UI Module"
                                 href="https://app.rebanknow.com/signup" className="v3-1-btn-get-started v3-1-btn-get-started-large w-button"><strong
                                  className="bold-text-21">Create your account</strong></a>
                              <a data-event-category="Demo booking" data-event-action="Click"
                                 data-event-label="Product UI Module - Book a demo"
                                 href="https://calendly.com/leona-rebank/30min" target="_blank"
                                 className="v3-btn-book-demo w-inline-block">
                                  <div className="text-block-66">Or speak to an expert</div>
                                  <img src="/images/6203f94ff8a2e8101731d4c0_arrow_forward.svg" loading="lazy" alt="Arrow icon" className="image-67"/>
                              </a>
                          </div>
                          <div className="div-block-85">
                              <div className="div-block-84">
                                  <img src="/images/pink-tick.svg" loading="lazy" alt="" className="image-74"/>
                                      <div className="text-block-68">Try FREE for 14 days</div>
                              </div>
                              <div className="div-block-84">
                                  <img src="/images/pink-tick.svg" loading="lazy" alt="" className="image-74"/>
                                      <div className="text-block-68">No card required</div>
                              </div>
                              <div className="div-block-84">
                                  <img src="/images/pink-tick.svg" loading="lazy" alt="" className="image-74"/>
                                      <div className="text-block-68">No switching banks</div>
                              </div>
                          </div>
                      </div>
              </div>
          </div>
          <div className="section-14 wf-section">
              <div className="v3-1-book-demo-container w-container">
                  <div className="div-block-81">
                      <div className="v3-book-demo-title">Let's show you around</div>
                      <div className="text-block-65">Want a personalised tour of the product?<br/><br/>Need a solution specific to your business?<br/><br/>Don’t
                          know how rebank
                          fits into your workflow?
                      </div>
                      <div className="v3-book-demo-cta-group">
                          <a data-event-category="Demo booking" data-event-action="Click" data-event-label="Book a demo section"
                             href="https://calendly.com/leona-rebank/30min" target="_blank" className="v3-book-demo-btn w-inline-block">
                              <div className="text-block-67">Speak to an expert</div>
                              <img src="/images/62150c147e4c5576e53e4590_arrow_white.svg" loading="lazy" alt="" className="image-71"/>
                          </a>
                          <div className="v3-book-demo-btn-subtext">
                              <img src="/images/621e182f296670464cdbbcf9_calendar_today.svg" loading="lazy" alt=""
                                   className="image-44"/>
                                  <div className="text-block-34">Find time today</div>
                          </div>
                      </div>
                  </div>
                  <img src="/images/621e1a7a97ac8f6ee5a55696_sitting.svg" loading="lazy" alt="" className="image-72"/>
              </div>
          </div>
          <div className="section-10 wf-section">
              <div data-w-id="f1951f4f-1bf7-e07e-ea28-c4958576dd57" className="v3-resources-container w-container">
                  <div className="v3-resources-title">Raise your financial game <em>today</em></div>
                  <div className="text-block-39">I'm a ...</div>
                  <div className="div-block-44">
                      <div className="div-block-45">
                          <a id="v3-resource-btn-startup" data-event-action="Click" data-event-label="Small startup"
                             data-event-category="Home Resources" href="https://www.rebanknow.com/#"
                             className="v3-link-large w-inline-block v3-resource-btn-selected">
                              <div className="text-block-38">Small startup</div>
                              <img src="/images/6203f94ff8a2e8101731d4c0_arrow_forward.svg" loading="lazy" id="v3-recources-option-arrow-startup"
                                   alt="Arrow icon"
                                   className="v3-recources-option-arrow"/>
                          </a>
                          <a id="v3-resource-btn-growing" data-event-action="Click" data-event-label="Growing business"
                             data-event-category="Home Resources" href="https://www.rebanknow.com/#"
                             className="v3-link-large w-inline-block">
                              <div className="text-block-38">Growing business</div>
                              <img src="/images/6203f94ff8a2e8101731d4c0_arrow_forward.svg" loading="lazy" id="v3-recources-option-arrow-growing"
                                   alt="Arrow icon"
                                   className="v3-recources-option-arrow"/>
                          </a>
                          <a id="v3-resource-btn-international" data-event-action="Click"
                             data-event-label="International company" data-event-category="Home Resources"
                             href="https://www.rebanknow.com/#" className="v3-link-large w-inline-block">
                              <div className="text-block-38">International company</div>
                              <img src="/images/6203f94ff8a2e8101731d4c0_arrow_forward.svg" loading="lazy" id="v3-recources-option-arrow-international"
                                   alt="Arrow icon" className="v3-recources-option-arrow"/>
                          </a>
                      </div>
                      <div className="div-block-70">
                          <a id="v3-resource-link-box-1" href="https://rebank.notion.site/Finance-for-Founders-f35f25d676204a5fab75e799d09b2b8d"
                             target="_blank" className="v3-box-shadow v3-free-resource-btn w-inline-block" data-event-category="Home Resources"
                             data-event-action="Click" data-event-label="Finance for Founders Guide">
                              <div id="v3-resource-text-1" className="v3-resource-link-text">Finance for Founders Guide</div>
                              <img src="/images/6203f94ff8a2e8101731d4c0_arrow_forward.svg" loading="lazy" alt="Arrow icon" className="image-49"/>
                          </a>
                          <a
                              id="v3-resource-link-box-2" href="https://www.rebanknow.com/post/how-we-got-into-y-combinator" target="_blank"
                              className="v3-box-shadow v3-free-resource-btn w-inline-block" data-event-category="Home Resources" data-event-action="Click"
                              data-event-label="How we got into Y Combinator (Part 1)">
                              <div id="v3-resource-text-2" className="v3-resource-link-text">How we got into Y Combinator (Part 1)</div>
                              <img src="/images/6203f94ff8a2e8101731d4c0_arrow_forward.svg" loading="lazy" alt="Arrow icon" className="image-49"/>
                          </a>
                      </div>
                  </div>
              </div>
          </div>

          <div className="section-7 wf-section">
              <div className="v3-testimonials-section w-container">
                  <div className="v3-testimonials-title">Founders speak louder than words</div>
                  <div data-w-id="4259ef9b-c75b-d6ee-5aa4-8d7cdf18dcc2" className="v3-testimonials-content">
                      <div className="v3-video-group-container">
                          <div className="v3-video-container">

                          </div>
                          <div className="text-block-41">Our beloved customer — <strong className="bold-text-22">Freddie, Founder @ Minimum</strong></div>
                      </div>
                      <div className="v3-quotes-group">
                          <div className="div-block-49">
                              <div className="v3-quote-block">
                                  <div className="v3-speech-mark">"</div>
                                  <div className="v3-quote-text"><strong>Rebank is AMAZING</strong>.<br/>We just onboarded within 30 minutes ... had a massive
                                      WOW moment on
                                      the dashboard<strong>.</strong>
                                  </div>
                                  <div className="v3-quote-author-container">
                                      <img src="/images/620d1102cf9e170457fcbd51_oleg-photo.jpeg" loading="lazy" alt="Oleg"
                                           className="image-50"/>
                                          <div className="text-block-40">Oleg, Founder @ <a href="https://coinrule.com/" data-event-category="External"
                                                                                            data-event-label="Home quote - Coinrule website"
                                                                                            data-event-action="Click"
                                                                                            target="_blank" className="v3-quote-link">Coinrule</a></div>
                                  </div>
                              </div>
                              <div className="v3-quote-block">
                                  <div className="v3-speech-mark">"</div>
                                  <div className="v3-quote-text"><strong>Absolutely awesome service</strong> and such a relief from dealing with traditional
                                      banks who are
                                      slow and expensive.
                                  </div>
                                  <div className="v3-quote-author-container">
                                      <img src="/images/620d141b855e8190504611eb_daniel-photo.jpeg" loading="lazy" alt="Daniel"
                                           className="image-50"/>
                                          <div className="text-block-40">Daniel, Founder @ <a href="https://sitenna.com/"
                                                                                              data-event-label="Home quote - Sitenna website"
                                                                                              data-event-category="External" data-event-action="Click"
                                                                                              target="_blank"
                                                                                              className="v3-quote-link">Sitenna</a></div>
                                  </div>
                              </div>
                          </div>
                          <div>
                              <div className="v3-quote-block">
                                  <div className="v3-speech-mark">"</div>
                                  <div className="v3-quote-text v3-quote-text-wide">The process was great —<br/><strong>definitely better</strong> than the usual
                                      pay run!
                                  </div>
                                  <div className="v3-quote-author-container">
                                      <img src="/images/620d144fa3e3e879cd314cd0_yousif-photo.jpeg" loading="lazy" alt="Yousif"
                                           className="image-50"/>
                                          <div className="text-block-40">Yousif, Head of Finance @ <a href="https://feast-it.com/"
                                                                                                      data-event-label="Home quote - Feast It website"
                                                                                                      data-event-category="External" data-event-action="Click"
                                                                                                      target="_blank"
                                                                                                      className="v3-quote-link">Feast It</a></div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

      </main>
    </Layout>
  )
}

export default IndexPage
