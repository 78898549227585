import React from 'react'
import {Link} from 'gatsby'

const Footer = () => (

  <footer>
    <div className="footer-section">
        <div className="section-8 wf-section">
            <div className="container-4 w-container">
                <img src="/images/6204f63782b74578d6dfa64b_paper-airplane-final.svg" loading="lazy" alt="Paper plane flying"
                     className="image-51"/>
                <div className="text-block-42">Bring all your finances together</div>
                <div className="v3-btn-final-cta-group">
                    <a data-event-action="Click" data-event-category="Sign Up" data-event-label="No switch CTA"
                       href="https://app.rebanknow.com/signup"
                       className="v3-btn-final-cta-get-started v3-btn-final-cta-get-started-large w-button"><strong className="bold-text-21">Create
                        your account</strong></a>
                    <a data-event-category="Demo booking" data-event-action="Click" data-event-label="No switch - Book a demo"
                       href="https://calendly.com/leona-rebank/30min" target="_blank" className="v3-btn-book-demo w-inline-block">
                        <div className="text-block-66">Or speak to an expert</div>
                        <img src="/images/6203f94ff8a2e8101731d4c0_arrow_forward.svg" loading="lazy" alt="Arrow icon" className="image-67"/>
                    </a>
                    <div className="div-block-85">
                        <div className="div-block-84">
                            <img src="/images/pink-tick.svg" loading="lazy" alt="" className="image-74"/>
                            <div className="text-block-68">Try FREE for 14 days</div>
                        </div>
                        <div className="div-block-84">
                            <img src="/images/pink-tick.svg" loading="lazy" alt="" className="image-74"/>
                            <div className="text-block-68">No card required</div>
                        </div>
                        <div className="div-block-84">
                            <img src="/images/pink-tick.svg" loading="lazy" alt="" className="image-74"/>
                            <div className="text-block-68">No switching banks</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="v3-footer-section wf-section">
            <div className="container-5 w-container">
                <div className="div-block-52">
                    <img src="/images/61127bf50328a76baf48f935_rebank_logo.svg" loading="lazy" alt=""
                         className="image-52"/>
                    <img src="/images/620510d3ab191467f6d07996_plant.svg" loading="lazy" alt="Plant image" className="image-53"/></div>
                <div className="div-block-51">
                    <div className="v3-footer-column w-clearfix">
                        <div className="text-block-43">Pages</div>
                        <a data-event-category="Footer Link" data-event-action="Click" data-event-label="Pricing" href="https://www.rebanknow.com/pricing"
                           className="v3-link v3-footer-link">Pricing</a>
                        <a href="https://www.rebanknow.com/faqs" data-event-category="Footer Link"
                           data-event-action="Click" data-event-label="FAQs" className="v3-link v3-footer-link">FAQs</a><a
                        data-event-category="Footer Link" data-event-action="Click" data-event-label="Features" href="https://www.rebanknow.com/features"
                        className="v3-link v3-footer-link">Features</a>
                        <a data-event-category="Footer Link" data-event-action="Click" data-event-label="Integrations"
                           href="https://www.rebanknow.com/integrations"
                           className="v3-link v3-footer-link">Integrations</a><a data-event-category="Footer Link"
                                                                                 data-event-action="Click"
                                                                                 data-event-label="Intercompany"
                                                                                 href="https://www.rebanknow.com/transfer-pricing"
                                                                                 className="v3-link v3-footer-link">Transfer
                        pricing</a>
                        <a data-event-category="Footer Link" data-event-action="Click" data-event-label="Payments"
                           href="https://www.rebanknow.com/features#payments" className="v3-link v3-footer-link">Payments</a>
                    </div>
                    <div className="v3-footer-column w-clearfix">
                        <div className="text-block-43">Resources</div>
                        <a data-event-category="Footer Link" data-event-action="Click" data-event-label="Blog" href="https://www.rebanknow.com/blog"
                           className="v3-link v3-footer-link">Blog</a>
                        <a data-event-category="Footer Link" data-event-action="Click" data-event-label="Slack"
                           href="https://www.rebanknow.com/slack" className="v3-link v3-footer-link">Rebank Slack app</a><a
                        data-event-category="Footer Link" data-event-action="Click" data-event-label="Finance for Founders Guide"
                        href="https://rebank.notion.site/Finance-for-Founders-f35f25d676204a5fab75e799d09b2b8d" target="_blank"
                        className="v3-link v3-footer-link">Finance
                        for Founders Guide</a><a data-event-category="Footer Link" data-event-action="Click" data-event-label="Roast My Finance Stack"
                                                 href="https://www.rebanknow.com/roast-my-finance-stack" className="v3-link v3-footer-link v3-hidden">Roast My
                        Finance
                        Stack</a>
                        <a data-event-category="Footer Link" data-event-action="Click" data-event-label="Currency converter"
                           href="https://www.rebanknow.com/currency-converter" className="v3-link v3-footer-link">Currency converter</a><a
                        data-event-category="Footer Link" data-event-action="Click" data-event-label="Runway calculator"
                        href="https://www.rebanknow.com/runway-calculator-v1" className="v3-link v3-footer-link">Runway calculator</a>
                    </div>
                    <div className="v3-footer-column w-clearfix">
                        <div className="text-block-43">Company</div>
                        <a data-event-category="Footer Link" data-event-action="Click" data-event-label="About" href="https://www.rebanknow.com/about"
                           className="v3-link v3-footer-link">About</a>
                        <a data-event-category="Footer Link" data-event-action="Click" data-event-label="Careers"
                           href="https://angel.co/company/rebanknow/jobs" target="_blank" className="v3-link v3-footer-link">Careers</a><a
                        data-event-category="Footer Link" data-event-action="Click" data-event-label="Contact us"
                        href="mailto:hello@rebanknow.com?subject=I%20have%20a%20question" className="v3-link v3-footer-link">Contact us</a>
                    </div>
                    <div className="v3-footer-column w-clearfix">
                        <div className="text-block-43">Social</div>
                        <a data-event-category="Footer Link" data-event-action="Click" data-event-label="LinkedIn"
                           href="https://www.linkedin.com/company/rebanknow"
                           target="_blank" className="v3-link v3-footer-link">LinkedIn</a>
                        <a data-event-category="Footer Link" data-event-action="Click"
                           data-event-label="Twitter" href="https://twitter.com/rebanknow" target="_blank"
                           className="v3-link v3-footer-link">Twitter</a><a data-event-category="Footer Link"
                                                                            data-event-action="Click"
                                                                            data-event-label="TikTok"
                                                                            href="https://www.tiktok.com/@rebanknow"
                                                                            target="_blank"
                                                                            className="v3-link v3-footer-link">TikTok</a><a
                        data-event-category="Footer Link" data-event-action="Click" data-event-label="Instagram" href="https://www.instagram.com/rebanknow/"
                        target="_blank" className="v3-link v3-footer-link">Instagram</a>
                        <a data-event-category="Footer Link" data-event-action="Click"
                           data-event-label="YouTube"
                           href="https://www.youtube.com/channel/UCqYpsMMTbKCnxThgWQ9tEmg"
                           target="_blank" className="v3-link v3-footer-link">YouTube</a><a
                        data-event-category="Footer Link" data-event-action="Click" data-event-label="Facebook" href="https://www.facebook.com/rebanknow"
                        target="_blank" className="v3-link v3-footer-link">Facebook</a>
                    </div>
                </div>
            </div>
        </div>
        <div className="section-9 wf-section">
            <div className="container-6 w-container">
                <div className="text-block-44">© 2022 DD Zone, Inc., All rights reserved.</div>
                <div className="div-block-63">
                    <a data-event-label="Terms and Conditions" data-event-category="Footer Link" data-event-action="Click"
                       href="https://www.rebanknow.com/terms-and-conditions" className="v3-link v3-subfooter-link">Terms and conditions</a><a
                    data-event-label="Privacy Policy" data-event-category="Footer Link" data-event-action="Click"
                    href="https://www.rebanknow.com/privacy-policy"
                    className="v3-link v3-subfooter-link">Privacy policy</a>
                    <a data-event-label="Cookie Policy" data-event-category="Footer Link"
                       data-event-action="Click" href="https://www.rebanknow.com/cookies-policy"
                       className="v3-link v3-subfooter-link">Cookie policy</a>
                    <a
                        data-event-label="Complaints Policy" data-event-category="Footer Link" data-event-action="Click"
                        href="https://www.rebanknow.com/complaints-policy" className="v3-link v3-subfooter-link">Complaints policy</a>
                </div>
            </div>
        </div>

    </div>
  </footer>
);

export default Footer
