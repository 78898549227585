import React from 'react'

const Header = () => (
    <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner"
         className="v3-nav w-nav">
        <div className="v3-nav-bar w-container">
            <a href="https://www.rebanknow.com/" data-event-category="Navigation" data-event-action="Navigation item clicked"
               data-event-label="Logo" aria-current="page" className="v3-nav-logo w-nav-brand w--current" aria-label="home">
                <img
                    src="/images/61127bf50328a76baf48f935_rebank_logo.svg" loading="lazy" alt="" className="v3-rebank-logo"/>
            </a>
            <nav role="navigation" className="v3-nav-main-links w-nav-menu">
                <div className="div-block-66">
                    <a data-event-category="Navigation" data-event-action="Navigation item clicked" data-event-label="Features"
                       href="https://www.rebanknow.com/features" className="v3-nav-btn w-inline-block">
                        <div>Features</div>
                        <div className="v3-nav-btn-underline"></div>
                    </a>
                    <a data-event-category="Navigation" data-event-action="Navigation item clicked" data-event-label="Pricing"
                       href="https://www.rebanknow.com/pricing" className="v3-nav-btn w-inline-block">
                        <div>Pricing</div>
                        <div className="v3-nav-btn-underline"></div>
                    </a>
                    <a data-event-category="Navigation" data-event-action="Navigation item clicked" data-event-label="Security"
                       href="https://www.rebanknow.com/security" className="v3-nav-btn w-inline-block">
                        <div>Security</div>
                        <div className="v3-nav-btn-underline"></div>
                    </a>
                    <a data-event-category="Navigation" data-event-action="Navigation item clicked" data-event-label="Blog"
                       href="https://www.rebanknow.com/blog"
                       className="v3-nav-btn w-inline-block">
                        <div>Blog</div>
                        <div className="v3-nav-btn-underline"></div>
                    </a>
                    <a data-event-category="Navigation" data-event-action="Navigation item clicked" data-event-label="FAQs"
                       href="https://www.rebanknow.com/faqs"
                       className="v3-nav-btn w-inline-block">
                        <div>FAQs</div>
                        <div className="v3-nav-btn-underline"></div>
                    </a>
                </div>
                <div className="v3-nav-app-links w-clearfix">
                    <a data-event-label="Sign in" data-event-category="Navigation" data-event-action="Navigation item clicked"
                       href="https://app.rebanknow.com/" className="v3-nav-btn w-inline-block">
                        <div className="text-block-56">Log in</div>
                        <div className="v3-nav-btn-underline"></div>
                    </a>
                    <a data-event-action="Click" data-event-category="Sign Up" data-event-label="Navbar Create Account"
                       href="https://app.rebanknow.com/signup"
                       className="v3-btn-get-started v3-btn-get-started-small w-button">Create your account</a>
                </div>
            </nav>
            <div className="v3-nav-burger-menu w-nav-button" aria-label="menu" role="button"
                 aria-controls="w-nav-overlay-0" aria-haspopup="menu" aria-expanded="false">
                <div className="w-icon-nav-menu"></div>
            </div>
        </div>
        <div className="v3-nav-shadow"></div>
        <div className="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"></div>
    </div>
);

export default Header
